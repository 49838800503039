import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Accordion, Button } from '/src/components/common'

const accordionItems = [
  {
    answer: '<p>In response to COVID-19, our delivery partners are offering no-contact delivery in selected areas of mainland Australia. Drivers may ask for verbal confirmation of receipt from a safe distance to satisfy the signature requirement. A photo of the delivered product may also be taken as proof of delivery.</p>',
    question: 'What are your covid-19 delivery restrictions?'
  },
  {
    answer: '<p>At this stage we deliver to select postcodes in mainland Australia. We are expanding our delivery service to more postcodes on an ongoing basis.</p>',
    question: 'Where do you deliver?'
  },
  {
    answer: '<p>Small items are usually shipped within 2 to 4 working days. Large orders are shipped within 4 to 10 days. Delivery times may vary depending on your location.</p>',
    question: 'How long will it take to receive my order?'
  },
  {
    answer: '<p>At this stage order tracking is not available. This feature will be available in the near future.</p>',
    question: 'Can I track my delivery order?'
  },
  {
    answer: '<p>You will receive an email and advising you when your order has been shipped. In some cases, you may also get a phone call to ensure we have the correct order details for you. </p>',
    question: 'How do i know when my order has been shipped?'
  },
  {
    answer: '<p>Absolutely. Simply choose “Pick Up” in the shipping option and the choose “Transport Company” </p>',
    question: 'Can I use my own shipping provider?'
  },
  {
    answer: '<p>We recommend ordering online prior to pick up as stocks may vary for physical stores and online orders. Ordering online will ensure that your item is reserved for you. No guarantees can be made for in-store pickup based on online inventory check unless an online order is placed. If in doubt, please ring your local branch to check stock levels. </p>',
    question: 'Can I pick up my order from a store without ordering online?'
  },
  {
    answer: '<p>You will receive an email and advising you when your order is ready. In some cases, you may also get a phone call to ensure we have the correct order details for you. </p>',
    question: 'How do I know when my order is ready for pick up?'
  },
  {
    answer: '<p>For orders over 20 kgs, where an in-person pick up has been selected, we require our customers to agree and adhere to safe loading guidelines. This is to ensure your safety and the safety of our staff and contractors is non-negotiable. Please note we reserve the right to refuse a pick up where loading guidelines are not met. </p>',
    question: 'Why do i have to agree to safe loading guidelines?'
  }
]

const FAQPage = () => {
  return (
    <Layout hero={<HeroImage title="Pickup and Delivery" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <p>See below pickup and delivery items to note.</p>
          <Accordion items={accordionItems} />
        </Col>
      </Row>
    </Layout>
  )
}

export default FAQPage

export const Head = () => {
  return (
    <>
      <title>Pickup & Delivery  | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}


